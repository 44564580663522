import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"
import Collapse from "../components/collapse"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark

  const bannerUrl = "/workshops/workshops-banner.jpg";

  const workshops = frontmatter.workshopsList;

  const [accordianOpen, setAccordianOpen] = React.useState(-1);

  return (
    <Layout title={frontmatter.title} description="A detailed list of the different types of Workshops and Programs that Anne is typically involved with.">
      <Banner title={frontmatter.title} imageUrl={bannerUrl}/>

      <section className="section has-background-secondary-faded has-text-centered">
        <div className="container" style={{position: "relative"}}>     
          <h3 className="has-text-secondary title is-3" style={{maxWidth:"900px", marginLeft: "auto", marginRight: "auto"}}>
            {frontmatter.ctaText}        
          </h3>
          <Link className="button is-secondary wide transition" to="/contact">GET IN TOUCH</Link>
        </div>
      </section>

      <section className="section">
        <div className="container extra-p-padding thin">
          {workshops.map((item, index) => (
            <div className="block spacing collapse-header" style={{paddingLeft:"80px"}} key={index}>
              <a className="content" style={{position:"relative"}} onClick={() => {
                if (index === accordianOpen) {
                  setAccordianOpen(-1);
                }
                else {
                  setAccordianOpen(index);
                }              
              }}>
              {(index === accordianOpen) &&
                <div className="plus-minus">
                  <svg width="48" height="5.143" viewBox="0 0 48 5.143"><g transform="translate(-205.5 -944.714)"><rect class="a" width="5.143" height="48" rx="1" transform="translate(253.5 944.714) rotate(90)"/></g></svg>
                </div>
              }
              {!(index === accordianOpen) &&
                <div className="plus-minus">
                  <svg width="48" height="48" viewBox="0 0 48 48"><g transform="translate(-205.5 -934)"><rect class="a" width="5.143" height="48" rx="1" transform="translate(226.929 934)"/><rect class="a" width="5.143" height="48" rx="1" transform="translate(253.5 955.429) rotate(90)"/></g></svg>
                </div>
              }
              <h3 className="title is-3 has-text-tertiary" >{item.title}</h3>
              </a>
              <Collapse open={index === accordianOpen}>
                <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(item.description)}} />
              </Collapse>
            </div>        
          ))}   
        </div>
      </section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        workshopsList { title, description }
        ctaText
      }
    }
  }
`